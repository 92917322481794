import React from "react"
// import Link from "gatsby-link"
import Layout from "../../layouts/index"

export default () => (
  <Layout path="pd">
    <div className="pd-hero luma">
      <div className="content-wrap">
        <div className="pd-top-info luma">
          <div className="pd-name">
            <div className="pd-logo"></div>
            <div className="title">
              <h2>Luma</h2>
              <span>人工智能虚拟客服</span>
            </div>
          </div>
          <h3>Luma 虚拟服务助理, 基于自然语言对话技术的客服管理解决方案</h3>
          <p>
            <strong>
              图形交互界面，可引导您自助部署和配置，支持智能的工单分派和实时响应服务
            </strong>
          </p>
          <p>
            <strong>释放分析师工时资源，摆脱重复工作</strong>
          </p>
          <p>
            <strong>
              可定制的Web组件，可灵活嵌入Sharepoint、内网、外网站点
            </strong>
          </p>
        </div>
      </div>
    </div>
    <div className="pd-section">
      <div className="content-wrap">
        <h3>适配多种企业IM应用环境，搭建7x24小时的不间断服务</h3>
        <h5>
          Luma可以适配多种企业已经部署的IM应用环境，包括微信、钉钉、Microsoft
          Teams、Google Hangouts、Slack、Skype、Facebook
          Messenger、WhatsApp。可以在这些应用场景上搭建7x24小时的不间断服务。
        </h5>
        <div className="pd-luma-connect">
          <div className="pd-luma-connect-icon"></div>
          <div className="pd-luma-connect-icon"></div>
          <div className="pd-luma-connect-icon"></div>
          <div className="pd-luma-connect-icon"></div>
          <div className="pd-luma-connect-icon"></div>
          <div className="pd-luma-connect-icon"></div>
          <div className="pd-luma-connect-icon"></div>
          <div className="pd-luma-connect-icon"></div>
        </div>
        <div className="pd-luma-screens"></div>
      </div>
    </div>
    <div className="pd-section luma-cost-reduce">
      <div className="content-wrap">
        <h3>通过Luma，可降低企业30%以上的成本</h3>
        <div className="luma-cost-reduce-info">
          <ul>
            <li>智能工单分派</li>
            <li>完成和执行工单</li>
            <li>无需用户登录工单系统</li>
            <li>工单自动追踪和关闭</li>
            <li>工单状态通知和任务提醒</li>
          </ul>
        </div>
        <h4>将Luma用于工单系统，企业可以</h4>
        <ul className="luma-cost-reduce-cons">
          <li>
            <span>确保每个工单的完整性</span>
            <p>避免分析师无效的时间与精力浪费</p>
          </li>
          <li>
            <span>自动处理多种任务</span>
            <p>进一步降低人工干预</p>
          </li>
          <li>
            <span>持续追踪最终用户需求</span>
            <p>降低分析师人工追踪的成本消耗</p>
          </li>
          <li>
            <span>机器学习持续进化</span>
            <p>根据最终用户兴趣点持续智能改进</p>
          </li>
        </ul>
      </div>
    </div>
    <div className="pd-section luma-solution">
      <div className="content-wrap">
        <h3>多种ITSM解决方案无缝集成</h3>
        <h5>
          Luma可以与BMC Remedy、Jira、ServiceNow、CA Service Desk
          Manager、Cherwell、
          <br />
          Connectwise、EasyVista、Freshservice等多种ITSM解决方案无缝集成。当然，它还连接到ServiceAI的智能服务管理解决方案。
          <br />
          <br />
          大多数情况下，<span>Luma可以在30天内完成部署及业务上线。</span>
        </h5>
        <div className="luma-solution-logos"></div>
      </div>
    </div>
    <div className="pd-section">
      <div className="content-wrap">
        <h3>基于对话的自动化工作流</h3>
        <h5>
          Luma不仅仅是一个智能聊天机器人。
          <br />
          它还包括一个自动化引擎，可以在没有分析师参与的情况下,
          <br />
          实现许多智能服务工作流。
        </h5>
        <div className="luma-interface-group">
          <div className="luma-interface"></div>
          <div className="luma-interface-text">
            <h4>极易上手</h4>
            <p>
              Luma的部署比你想象的更快更简单。在许多情况下，客户可以在
              <span>30天内完成部署及业务上线</span>，
              在此过程中只需要对您的专业客服团队进行基础培训。
            </p>
          </div>
        </div>
        <div className="luma-interface-group">
          <div className="luma-interface-text">
            <h4>开箱即用</h4>
            <p>
              Luma直观的用户界面使客服系统管理员能够直观、快速地配置自动化工作流和查看集成状态。
              Luma预设了<span>超过25个开箱即用的工作流</span>，并持续更新。
            </p>
          </div>
          <div className="luma-interface"></div>
        </div>
      </div>
    </div>
  </Layout>
)
